<template>
  <a-modal
    :visible="isVisible"
    :closable="false"
    title="Export Excel"
  >
    <a-form-model :model="form" class="export-inventory">
      <a-form-model-item label="Bisnis">
        <a-select
          v-model="form.business_id"
          mode="multiple"
          class="w-100"
          placeholder="Pilih Bisnis"
          show-search
          allow-clear
          :options="businessList"
          option-filter-prop="children"
          :filter-option="filterOption"
          :disabled="isLoading"
          @deselect="onDeselectBusiness"
          @change="onChangeValue($event, 'business_id')"
        />
      </a-form-model-item>
      <a-form-model-item label="Gudang">
        <a-select
          v-model="form.warehouse_id"
          mode="multiple"
          class="w-100"
          placeholder="Pilih Gudang"
          show-search
          allow-clear
          option-filter-prop="children"
          :filter-option="filterOption"
          :not-found-content="isFetching ? undefined : null"
          :disabled="!form.business_id.length || isLoading"
          @focus="onFocusWarehouse"
          @change="onChangeValue($event, 'warehouse_id')"
        >
          <a-spin v-if="isFetching" slot="notFoundContent" size="small" />
          <a-select-option v-for="warehouse in warehouseList" :key="warehouse.value">
            {{ warehouse.label }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="Status">
        <a-select
          v-model="form.status"
          class="w-100"
          placeholder="Pilih Status Stock"
          show-search
          option-filter-prop="children"
          :filter-option="filterOption"
          :disabled="isLoading"
        >
          <a-select-option v-for="stat in statusList" :key="stat.key">
            {{ stat.title }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
    <template #footer>
      <a-button class="mr-2" :disabled="isLoading" @click="onClose">
        Cancel
      </a-button>
      <a-button
        type="primary"
        :loading="isLoading"
        :disabled="!form.business_id.length || !form.warehouse_id.length || isLoading"
        @click="$emit('handleSubmitModal', { form, warehouses: currentWarehouseList })"
      >
        Export Excel
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import { getWarehouseList } from '@/api/warehouse'
import isEqual from 'lodash/isEqual'

export default {
  name: 'ModalExportInventory',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Array,
      default: () => [],
    },
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isVisible: this.visible,
      isFetching: false,
      form: {
        business_id: [],
        warehouse_id: [],
        status: 'ALL',
      },
      warehouseList: [],
      currentBusinessId: [],
      currentWarehouseList: [],
    }
  },
  computed: {
    businessId() {
      return this.$store.state.user.restriction_base === 'Warehouse'
        ? Object.keys(this.$store.getters['user/businessListObjectByKey'])[0]
        : this.$route.query.business_id
    },
    businessList() {
      let list = [{ value: 'all', label: 'Semua Bisnis' }]
      if (!this.form.business_id.includes('all')) {
        this.$store.state.user.businessList?.forEach(business => {
          if (!!this.businessId) {
            if (this.businessId === business.business_id) {
              list.push({ value: business.business_id, label: business.business_name })
            }
          } else {
            if (business.business_id !== 'all') {
              list.push({ value: business.business_id, label: business.business_name })
            }
          }
        })
      }

      return list
    },
    statusList() {
      return this.status
    },
    isLoading() {
      return this.loading
    },
  },
  watch: {
    visible(value) {
      this.isVisible = value
      if (!value) {
        this.resetForm()
      } else if (value && !!this.businessId) {
        this.form.business_id = [this.businessId]
        this.onFocusWarehouse()
      }
    },
  },
  methods: {
    async fetchWarehouseList() {
      let list = []
      if (this.form.business_id.length && this.form.business_id.includes('all')) {
        this.$store.state.user.businessList?.forEach(business => {
          if (!!this.businessId) {
            if (this.businessId === business.business_id) {
              list.push(business.business_id)
            }
          } else {
            if (business.business_id !== 'all') {
              list.push(business.business_id)
            }
          }
        })
      }

      await getWarehouseList({
        business_id: this.form.business_id.length && this.form.business_id.includes('all')
          ? list.join()
          : this.form.business_id.length && !this.form.business_id.includes('all')
          ? this.form.business_id.join()
          : undefined,
        warehouse_id: this.$route.query.warehouse_id,
      })
      .then(({ data: { data: warehouses } }) => {
        let list = [{ value: 'all', label: 'Semua Gudang' }]
        warehouses?.forEach(warehouse => {
          list.push({ value: warehouse.id, label: warehouse.name })
        })

        this.warehouseList = !this.form.warehouse_id.length || (this.form.warehouse_id.length && !this.form.warehouse_id.includes('all'))
          ? list
          : [{ value: 'all', label: 'Semua Gudang' }]
        this.currentWarehouseList = list

        if (!!this.$route.query.warehouse_id) this.form.warehouse_id = [this.$route.query.warehouse_id]
        if (this.form.warehouse_id.length && !this.form.warehouse_id.includes('all')) {
          this.form.warehouse_id = this.form.warehouse_id.filter(warehouse => {
            const warehouseIndex = list.findIndex(whs => whs.value === warehouse)
            return warehouseIndex >= 0
          })
        }
      })
      .catch(err => {
        console.error(err)
        this.warehouseList = []
        this.currentWarehouseList = []
      })
      .finally(() => this.isFetching = false)
    },
    onChangeValue(value, type) {
      this.form[type] = value.includes('all')
        ? ['all']
        : value

      if (type === 'business_id' && !value.length) {
        this.form.warehouse_id = []
        this.warehouseList = []
        this.currentWarehouseList = []
      } else if (type === 'warehouse_id') {
        this.warehouseList = value.includes('all')
          ? [{ value: 'all', label: 'Semua Gudang' }]
          : this.currentWarehouseList
      }
    },
    onDeselectBusiness(value) {
      this.form.business_id = this.form.business_id.length
        ? this.form.business_id.filter(business => business !== value)
        : []
      this.onFocusWarehouse()
    },
    onFocusWarehouse() {
      this.isFetching = true
      const isSame = isEqual(this.currentBusinessId, this.form.business_id)
      if (!isSame) {
        this.warehouseList = []
        this.currentWarehouseList = []
        this.currentBusinessId = this.form.business_id

        if (this.form.business_id.length) {
          this.fetchWarehouseList()
        } else {
          this.isFetching = false
        }
      } else {
        this.isFetching = false
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0]?.text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    resetForm() {
      this.isFetching = false
      this.form = {
        business_id: [],
        warehouse_id: [],
        status: 'ALL',
      }
      this.warehouseList = []
      this.currentBusinessId = []
      this.currentWarehouseList = []
    },
  },
}
</script>

<style lang="scss">
.export-inventory {
  .ant-form-item {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .ant-form-item-label {
    line-height: normal;
  }
}
</style>